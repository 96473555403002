import React, { useEffect, useRef, useState } from "react";
import { ConstantUtils } from "../../utils/ConstantUtils";
import { BillConsumptionService } from "../../services/graphs/billConsumptionService";
import { getData, postData } from "../../api/apiDataExchange";
import Select, { components } from "react-select";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import LineChartEnergyView from "../../containers/graphs/LineChartEnergyView";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import LegendComponent from "../../components/chart/LegendComponent";
import { getUserType } from "../../utils/authenticationUtils";

const SummaryView = () => {
  const constantUtils = new ConstantUtils();
  const billService = new BillConsumptionService();

  const [annualEnergyconsumptionData, setAnnualEnergyconsumptionData] =
    useState([]);
  const [annualEnergyMonthLabels, setAnnualEnergyMonthLabels] = useState([]);
  const [annualEnergyConsumptionLabels, setAnnualConsumptionLabels] = useState(
    []
  );

  const [organisationList, setOrganisationList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [defaultSelectedOrg, setDefaultSelectedOrg] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [selectedFactory, setSelectedFactory] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [meterList, setMeterList] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedFactOptions, setSelectedFactOptions] = useState([]);
  const [selectedUnitOptions, setSelectedUnitOptions] = useState([]);
  const [selectedMeterOptions, setSelectedMeterOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("orgs");
  const [selectedFilterList, setSelectedFilterList] = useState("orgs");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const dateRangePickerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(2023, 3, 1),
    endDate: new Date(2024, 2, 30),
    key: "selection",
  });

  const [dateChange, setDateChange] = useState(0);
  const [energyData, setEnergyData] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  useEffect(() => {
    const getOrgansiationList = async () => {
      const organisationList = await billService.getAllOrganisations(
        selectedOrg
      );
      setOrganisationList(organisationList);
    };
    getOrgansiationList();
  }, []);

  useEffect(() => {
    if (selectedOrg.length > 0) {
      setDefaultSelectedOrg([]);
    } else if (organisationList.length > 0) {
      //      // console.log(organisationList);
      setType("Months");
      // setDefaultSelectedOrg(organisationList.map(org => ({
      //   label: org.name,
      //   value: org._id
      // })));
      setSelectedOrg(
        organisationList.map((org) => ({
          label: org.name,
          value: org._id,
        }))
      );
      setSelectedOptions(
        organisationList.map((org) => ({
          label: org.name,
          value: org._id,
        }))
      );
    }
  }, [organisationList, selectedOrg]);
  //  // console.log(selectedOrg);
  // useEffect(() => {
  //   const getTreeData = async () => {
  //     // Reset lists when selectedOrgs changes
  //     setFactoryList([]);
  //     setUnitList([]);
  //     setMeterList([]);
  //     if (selectedOrg.length > 0) {
  //       const organisationsData = await Promise.all(
  //         selectedOrg.map(orgId =>
  //           getData(`${process.env.REACT_APP_API_URL}/organisation/tree/${orgId.value}`)
  //         )
  //       );

  //       const allFactories = organisationsData.flatMap(data => data?.data?.factories || []);
  //       setFactoryList(allFactories);
  //       if (selectedFactory.length > 0) {
  //         const filteredFactories = allFactories.filter(factory =>
  //           selectedFactory.some(selected => selected.value === factory._id)
  //         );

  //         setUnitList(filteredFactories.flatMap(factory => factory.units));

  //         if (selectedUnit.length > 0) {
  //           const filteredUnits = filteredFactories.flatMap(factory => factory.units).filter(unit => selectedUnit.some(selected => selected.value === unit._id));
  //           const allMeters = filteredUnits.flatMap(unit => unit.meters);
  //           // Set meterList with all available meters, or an empty array if there are no meters
  //           setMeterList(allMeters || []);
  //         } else {
  //           // No units are selected, reset meter list
  //           setMeterList([]);
  //         }
  //       }
  //     }
  //   };

  //   getTreeData();
  // }, [selectedOrg, selectedFactory, selectedUnit]);

  useEffect(() => {
    const getTreeData = async () => {
      // Reset lists when selectedOrgs changes
      setFactoryList([]);
      setUnitList([]);
      setMeterList([]);
      const organisationIds = organisationList.map(org => org._id);
      if (organisationIds.length > 0) {
        const usertype = await getUserType();
        let organisationsData;
        if (usertype !== "admin") {
          organisationsData= await Promise.all(
            organisationIds.map(orgId =>
                getData(`${process.env.REACT_APP_API_URL}/organisation/tree/${orgId}?buyerId=${localStorage.getItem("userId")}`)
            )
        )}else{
          organisationsData= await Promise.all(
            organisationIds.map(orgId =>
                getData(`${process.env.REACT_APP_API_URL}/organisation/tree/${orgId}`)
            )
          )
        }
     

          const allFactories = organisationsData?.flatMap(data =>
              (data?.data?.factories || []).map(factory => ({
                  ...factory,
                  organisationId: data.data._id
              }))
          );
        setFactoryList(allFactories);

        // Collect all meters directly from the selected organizations
        const allMeters = allFactories.flatMap((factory) =>
          factory.units.flatMap((unit) => unit.meters)
        );

        setMeterList(allMeters);
        console.log({allMeters});

        const formatedMeters = allMeters.map((mtr)=>{return {label:mtr['consumerNo'],value:mtr['_id']}});
        console.log({formatedMeters});
        const userType = await getUserType();
        if (usertype !== 'admin') {
          setSelectedMeterOptions(formatedMeters[0]);
          setSelectedMeter([formatedMeters[0]]);
        }
 
        // If there are selected units, filter meters based on those units
        if (selectedUnit.length > 0) {
          const selectedUnitIds = selectedUnit.map((unit) => unit.value);
          const filteredMeters = allMeters.filter((meter) =>
            selectedUnitIds.includes(meter.unitId)
          );
          console.log({filteredMeters});
          
          setMeterList(filteredMeters);
        }

        // If there are selected factories, update unit list based on those factories
        if (selectedFactory.length > 0) {
          const selectedFactoryIds = selectedFactory.map(
            (factory) => factory.value
          );
          const filteredFactories = allFactories.filter((factory) =>
            selectedFactoryIds.includes(factory._id)
          );
          setUnitList(filteredFactories.flatMap((factory) => factory.units));
        }
      }
    };

    getTreeData();
  }, [selectedOrg, selectedFactory, selectedUnit]);

  // const [labels, setLabels] = useState(months);

  function getMonthName(monthIndex) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthIndex];
  }

  const [comparisonData, setComparisonData] = useState([]);

  const formatDate = (date) => {
    if (!date) return "";
    const adjustedDate = new Date(date);
    adjustedDate.setDate(adjustedDate.getDate() + 1);
    return adjustedDate.toISOString();
  };

  const fetchComparisonData = async (idKey, idValue) => {
    const filters = {
      startDate: formatDate(selectionRange?.startDate),
      endDate: formatDate(selectionRange?.endDate),
      [idKey]: idValue,
    };

    try {
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/visualization/compareView`,
        filters
      );

      if (response?.data?.data?.type === "day") {
        setType("Days");
        const compareData = response.data.data.data;
        const compareDaysData = compareData.map((data) => {
          const date = new Date(data.date);
          const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          const dayName = days[date.getDay()];
          const dateFormatted = `${dayName} (${date.getDate()} ${getMonthName(
            date.getMonth()
          )})`;
          return dateFormatted;
        });
        const compareEnergyData = compareData.map((data) => data.energy);

        return {
          days: compareDaysData,
          energyData: compareEnergyData,
        };
      }

      if (response?.data?.data?.type === "month") {
        setType("Months");
        const compareMonthsData = response.data.data.data.map(
          (data) => data.month
        );
        const compareEnergyData = response.data.data.data.map(
          (data) => data.energy 
        );
        const compareDateData = response.data.data.data.map(
          (data) => data.date
        );
        return {
          months: compareMonthsData,
          energyData: compareEnergyData,
          currentReadingDate: compareDateData,
        };
      }

      if (response?.data?.data?.type === "year") {
        setType("Years");
        const compareYearsData = response.data.data.data.map(
          (data) => data.year
        );
        const compareEnergyData = response.data.data.data.map(
          (data) => data.energy
        );
        return {
          years: compareYearsData,
          energyData: compareEnergyData,
        };
      }
    } catch (error) {
      console.error(`Error fetching data for ID ${idValue}:`, error);
      return null; // Handle errors gracefully, return null for failed requests
    }
  };

  const [currentGranular, setCurrentGranular] = useState("organisation");

  const fetchData = async (type, ids) => {
    setIsLoading(true);
    const allData = await Promise.all(
      ids.map(async (id) => {
        const data = await fetchComparisonData(`${type}Id`, id.value);
        //        // console.log(data);
        const orgId = id.value;
        const orgName = id.label;
        return { orgId, data, orgName };
      })
    );
    //    console.log({allData});
    const validData = allData.filter((data) => data.data !== undefined);
    //    // console.log(validData);

    const mergedData = allData.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.orgId]: {
          data: curr.data,
          orgName: curr.orgName,
        },
      }),
      {}
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 500);

    //    console.log({ mergedData });
    return mergedData;
  };
  //  // console.log(currentGranular);
  const [orgChange, setOrgCahnge] = useState(0);
  const [factChange, setFactCahnge] = useState(0);
  const [unitChange, setUnitCahnge] = useState(0);
  const [meterChange, setMeterCahnge] = useState(0);
  useEffect(() => {
    //    // console.log(selectionRange.startDate);
    //    // console.log(selectionRange.endDate);
    if (
      selectionRange.startDate !== "" &&
      selectionRange.endDate !== "" &&
      selectionRange.startDate !== selectionRange.endDate
    ) {
      setDateChange((prevValue) => prevValue + 1);
      if (currentGranular === "organisation") {
        setOrgCahnge((prevValue) => prevValue + 1);
      } else if (currentGranular === "factory") {
        setFactCahnge((prevValue) => prevValue + 1);
      } else if (currentGranular === "unit") {
        setUnitCahnge((prevValue) => prevValue + 1);
      } else if (currentGranular === "meter") {
        setMeterCahnge((prevValue) => prevValue + 1);
      }
    }
  }, [selectionRange.startDate, selectionRange.endDate]);

  useEffect(() => {
    if (selectedOrg.length === 0) return;
    //    console.log({selectedOrg});
    //    console.log({currentGranular})
    if (currentGranular === "organisation") {
      //        console.log({currentGranular})
      fetchData("organisation", selectedOrg).then((mergedData) => {
        if (currentGranular === "organisation") {
          setComparisonData(mergedData);
          setCurrentGranular("organisation");
        } else {
          setComparisonData(mergedData);
          setCurrentGranular("organisation");
        }
      });
    }
  }, [selectedOrg, orgChange, currentGranular]);

  useEffect(() => {
    if (defaultSelectedOrg.length === 0) return;
    //    // console.log(defaultSelectedOrg, 'defaultSelectedOrg');
    fetchData("organisation", defaultSelectedOrg).then((mergedData) => {
      if (currentGranular === "organisation") {
        setComparisonData((prevData) => ({ ...prevData, ...mergedData }));
      } else {
        setComparisonData(mergedData);
        setCurrentGranular("organisation");
      }
    });
  }, [defaultSelectedOrg]);

  useEffect(() => {
    if (selectedFactory.length === 0) return;
    fetchData("factory", selectedFactory).then((mergedData) => {
      if (currentGranular === "factory") {
        setComparisonData((prevData) => ({ ...prevData, ...mergedData }));
      } else {
        setComparisonData(mergedData);
        setCurrentGranular("factory");
      }
    });
  }, [selectedFactory, factChange]);

  //   useEffect(() => {
  //     // if (selectedUnit.length === 0) {
  //     //   setComparisonData({});
  //     // };
  //     fetchData("unit", selectedUnit).then((mergedData) => {
  //       if (currentGranular === "unit") {
  //         setComparisonData((prevData) => ({ ...prevData, ...mergedData }));
  //       } else {
  //         setComparisonData(mergedData);
  //         setCurrentGranular("unit");
  //       }
  //     });
  //   }, [selectedUnit, unitChange]);

  useEffect(() => {
    if (selectedMeter.length === 0 && meterList.length > 0) {
      setCurrentGranular("organisation");
    } else {
      console.log({selectedMeter,meterList});
      
      fetchData("meter", selectedMeter).then((mergedData) => {
        if (currentGranular === "meter") {
          setComparisonData(mergedData);
          setCurrentGranular("meter");
        } else {
          setComparisonData(mergedData);
          setCurrentGranular("meter");
        }
      });
    }
  }, [selectedMeter, meterChange,meterList]);

  const handleOrgChange = (selected) => {
    setSelectedOptions(selected);
    setSelectedOrg(
      selected.map((option) => ({
        label: option.label,
        value: option.value,
      }))
    );
  };
  const handleFactChange = (selected) => {
    setSelectedFactOptions(selected);
    setSelectedFactory(
      selected.map((option) => ({
        label: option.label,
        value: option.value,
      }))
    );
  };

  const handleUnitChange = (selected) => {
    setSelectedUnitOptions(selected);
    setSelectedUnit(
      selected.map((option) => ({
        label: option.label,
        value: option.value,
      }))
    );
  };

  const handleMeterChange = (selected) => {
    console.log({selected});
    
    setSelectedMeterOptions(selected);
    setSelectedMeter(
      selected.map((option) => ({
        label: option.label,
        value: option.value,
      }))
    );
  };

  const CheckboxOption = (props) => {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          // onClick={(e) => e.stopPropagation()}
          readOnly
        />{" "}
        <label style={{ marginLeft: "8px" }}>{props.label}</label>
      </components.Option>
    );
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    // handleDateRangeChange([ranges.selection]);
  };

  const handleInputClick = () => {
    setIsCalendarOpen(true);
  };

  const handleOutsideClick = (event) => {
    if (
      dateRangePickerRef.current &&
      !dateRangePickerRef.current.contains(event.target)
    ) {
      setIsCalendarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [options, setOptions] = useState({
    labels: ["dasda", "dadas"],
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return value === 0 ? 'N/A' : value?.toLocaleString('en-IN');;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: type, // Use the current value of type
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Energy (kwh)",
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return value / 1000 + "k"; // Converts 1000 to 1k, 2000 to 2k, etc.
            }
            return value;
          },
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  });
  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      scales: {
        ...prevOptions.scales,
        x: {
          ...prevOptions.scales.x,
          title: {
            ...prevOptions.scales.x.title,
            text: type,
          },
        },
      },
    }));
  }, [type]);

  const labels = Object.values(comparisonData)?.reduce((acc, curr) => {
    if (curr.data?.days?.length > 0) {
      return curr.data.days;
    } else if (curr?.data?.months?.length > 0) {
      return curr.data.currentReadingDate.map((date, index) => {
        if (date !== "") {
          const currentDate = new Date(date);
          const day = currentDate.toLocaleString("default", { day: "2-digit" });
          const month = currentDate.toLocaleString("default", {
            month: "short",
          });
          let year = currentDate.getFullYear();
          const firstDayOfMonth = new Date(year, index, 1);
          const formattedFirstDay = firstDayOfMonth.toLocaleString("default", {
            day: "2-digit",
          });
          return `${formattedFirstDay} ${month} ${year}`;
        } else {
          return curr.data.months[index];
        }
      });
    } else if (curr?.data?.years?.length > 0) {
      return curr.data.years;
    }
  }, []);
  //  // console.log(comparisonData);
  const data = {
    labels: labels,
    datasets: Object.entries(comparisonData)?.map(
      ([orgId, orgDetails], index) => {
        const colorIndex = index % constantUtils.getLineGraphColors().length;
        const data = comparisonData[orgId];
        return {
          label: orgDetails?.orgName,
          data: data?.data?.energyData,
          borderWidth: 2,
          type: "line",
          borderColor: constantUtils.getLineGraphColors()[colorIndex],
          backgroundColor: constantUtils.getLineGraphColors()[colorIndex],
        };
      }
    ),
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontSize: "10px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "10px",
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "10px", // Setting border radius to 20px
    }),
  };
  //  // console.log(comparisonData);

  return (
    <div className="flex gap-[10px] w-full">
      <div className="w-full">
        <div
          className={`${
            selectedFilter === "orgs" ? "" : "mb-[10px]"
          }   mb-2 sm:grid sm:grid-cols-3 w-full lg:grid-cols-3 sm:gap-[10px] sm:justify-end px-[25px] sm:px-0`}
        >
          <div className={`w-[100%]`}>
            <Select
              components={{ Option: CheckboxOption }}
              isMulti
              className="min-w-[160px] w-full"
              options={organisationList?.map((org) => ({
                label: org.name,
                value: org._id,
              }))}
              onChange={handleOrgChange}
              value={selectedOptions}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              isClearable={false}
              placeholder="Select Organisation"
              classNamePrefix="custom-select"
              styles={customStyles}
              controlShouldRenderValue={false}
            />
          </div>

          {/* <div
            className={`w-[90%]`}
          > */}
          {/* <Select
              components={{ Option: CheckboxOption }}
              isMulti
               options={factoryList?.map(org => ({ label: org.name, value: org._id }))}
              onChange={handleFactChange}
              value={selectedFactOptions}
              closeMenuOnSelect={true}
              hideSelectedOptions={true}
              isClearable={false}
              placeholder="Select Factroy"
              classNamePrefix="custom-select"
              styles={customStyles}
            /> */}
          {/* </div> */}

          {/* <div
            className={`w-[90%]`}
          >
            <Select
              components={{ Option: CheckboxOption }}
              isMulti
              options={unitList?.map(org => ({ label: org.name, value: org._id }))}
              onChange={handleUnitChange}
              // value={selectedUnitOptions}
              closeMenuOnSelect={true}
              hideSelectedOptions={true}
              isClearable={false}
              placeholder="Select Unit"
              classNamePrefix="custom-select"
              styles={customStyles}
            />
          </div> */}

          <div className={`w-[100%] my-[15px] sm:my-0 custom-multi-select`}>
            <Select
              components={{ Option: CheckboxOption }}
              isMulti
              className="min-w-[180px] w-full"
              options={meterList?.map((org) => ({
                label: org.consumerNo,
                value: org._id,
              }))}
              onChange={handleMeterChange}
              value={selectedMeterOptions}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              isClearable={false}
              placeholder="Select Consumer No"
              classNamePrefix="custom-select"
              styles={customStyles}
              controlShouldRenderValue={false}
            />
          </div>

          <div className="w-[100%] date-range-picker-container relative custom">
            <input
              type="text"
              value={`${selectionRange.startDate.toDateString()} - ${selectionRange.endDate.toDateString()}`}
              onClick={handleInputClick}
              readOnly
              className="date-range-input rounded-[10px] text-[10px]  sm:w-[190px] lg:w-[200px] leading-[20px] h-[36px] px-[5px]"
            />
            {isCalendarOpen && (
              <div
                className="absolute bg-black shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] mt-2"
                ref={dateRangePickerRef}
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  showMonthAndYearPickers={true}
                  direction="horizontal"
                  className="date-range-picker"
                  editableDateInputs={true}
                  inputRanges={[]}
                  staticRanges={[]}
                  onClose={() => setIsCalendarOpen(false)}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={`flex flex-col sm:shadow-[0_4px_4px_0_#00000040] bg-white rounded-[10px]  w-full`}
        >
          <div className="flex-col  sm:shadow-[0_4px_4px_0_#00000040] min-h-[200px] sm:min-h-[400px]  w-full">
            <div className={` flex justify-between  px-[35px] py-4  w-full`}>
              {/* <div>Consumption View </div> */}
            </div>
            {isLoading ? (
              <div className="w-full h-full min-h-[400px]    flex justify-center items-center">
                <LoaderComponent />
              </div>
            ) : (
              <>
                <div className=""></div>
                <div className="sm:px-[35px] lg:py-[25px]">
                  <div className="w-full justify-center aggregatedView overflow-x-scroll ">
                  <div className="">
                    <LegendComponent data={data} />
                  </div>
                  </div>
                  <LineChartEnergyView data={data} options={options} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryView;
