import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const ContactAdministrator = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md">
        <div className="text-red-500 mb-4">
          <FontAwesomeIcon icon={faLock} size="3x" />
        </div>
        <h1 className="text-2xl font-semibold text-gray-800 mb-2">Access Denied</h1>
        <p className="text-gray-600 mb-4">You do not have the required permissions to view this page. Please contact the administrator for assistance.</p>
        <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
          Contact Administrator
        </button>
      </div>
    </div>
  );
};

export default ContactAdministrator;