// JobProgressContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
// import { field } from '../utils/BillUploadUtils';
// import { fieldNames } from '../utils/BillUploadUtils';
// import { units } from '../utils/BillUploadUtils';
import { uploadExcelData, uploadOcrImage } from '../api/uploadFile';
import { postOcrList, updateData } from '../api/apiDataExchange';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JobProgressContext = createContext();

export const useJobProgress = () => useContext(JobProgressContext);

export const JobProgressProvider = ({ children }) => {

    const [succesfullJobLength, setSuccesfullJobLength] = useState(0);
    const [jobErrorMessage, setJobErrorMessage] = useState("");
    const [jobIdLength, setJobIdLength] = useState(0);
    const [helpText, setHelpHext] = useState(false);
    const [errorLength, setErrorLength] = useState(0);
    const [field, setField] = useState([]);
    const [fieldNames, setFieldNames] = useState([]);
    const [units, setUnits] = useState([]);
    const [discomCode, setDiscomCode] = useState("");
    const [consumerType, setConsumerType] = useState('');
    let trackJobId = 0;
    let trackJobIdLength = 0;

    useEffect(() => {
        const processIndustrialData = () => {

            const tariff = consumerType.toLowerCase()
            const configData = localStorage.getItem('config');
            if (!configData) {
                console.log("No config data found");
                return;
            }
            if (discomCode !== "" && consumerType !== "") {
                const parsedData = JSON.parse(configData);
                if (parsedData.billMapping[discomCode]) {
                    if (!parsedData.billMapping || !parsedData.billMapping[discomCode][tariff]) {
                        console.log("Invalid config data structure");
                        return;
                    }

                    const industrial = parsedData.billMapping[discomCode][tariff];
                    const configLabels = [];
                    const configDBColumnNames = [];
                    const configUnits = [];

                    industrial?.forEach(item => {
                        configLabels.push(item.label);
                        configDBColumnNames.push(item.dbColumnName);
                        configUnits.push(item.unit);
                    });
                    setField(configLabels);
                    setFieldNames(configDBColumnNames);
                    setUnits(configUnits);

                }
            }

        };

        processIndustrialData();
    }, [discomCode, consumerType]);

    function extractKeysAndValues(obj) {
        const values = [];
        // console.log(obj);
        // console.log(fieldNames);
        fieldNames.forEach(fieldName => {
            let value;
            if (fieldName) {
                value = obj[fieldName];
                if (fieldName.startsWith('slot')) {
                    const parts = fieldName.split('-');
                    const slotNumber = parseInt(parts[1]);
                    const slotName = parts[2];
                    if (obj['todZone']?.length >= slotNumber) {
                        value = obj['todZone'][slotNumber - 1][slotName == "unit" ? "units" : slotName];
                    }

                } else if (fieldName == "billDate" || fieldName == "dueDate" || fieldName == "dateOfConnection" || fieldName == "readingDate" || fieldName == "previousReadingDate" || fieldName == "billDate") {
                    if (value && value.value) {
                        const dateParts = value.value.split('/');
                        const datePart = value.value.split('-');
                        // console.log(dateParts);
                        // console.log(datePart);
                        if (dateParts.length === 3) {
                            const day = parseInt(dateParts[0], 10);
                            const month = parseInt(dateParts[1], 10);
                            const year = parseInt(dateParts[2], 10);
                            if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
                                const dateObj = new Date(year, month - 1, day);
                                if (!isNaN(dateObj.getTime())) {
                                    const formattedDay = day.toString().padStart(2, '0');
                                    const formattedMonth = month.toString().padStart(2, '0');
                                    const formattedYear = year.toString();
                                    value = `${formattedDay}-${formattedMonth}-${formattedYear}`;
                                } else {
                                    value = "";
                                }
                            } else {
                                value = "";
                            }
                        } else if (datePart.length === 3) {
                            const day = parseInt(datePart[0], 10);
                            const monthStr = datePart[1];
                            // const yearStr = datePart[2];
                            const monthIndex = 0;
                            const year = parseInt(datePart[2], 10);
                            if (!isNaN(day) && !isNaN(monthIndex) && !isNaN(year)) {
                                const dateObj = new Date(year, monthIndex, day);
                                if (!isNaN(dateObj.getTime())) {
                                    const formattedDay = day.toString().padStart(2, '0');
                                    const formattedMonth = (monthIndex + 1).toString().padStart(2, '0'); // Add 1 to convert to 1-based index
                                    const formattedYear = year.toString();
                                    value = `${formattedDay}-${formattedMonth}-${formattedYear}`;
                                    // console.log(value);
                                } else {
                                    value = "";
                                }
                            } else {
                                value = "";
                            }

                        } else {
                            value = "";
                        }
                    } else {
                        value = "";
                    }

                }
                else if (fieldName == "units" || fieldName == "rate") {
                    const category = obj["category"];
                    if(!obj["consumptionType"]){
                        obj["consumptionType"] = [
                            {
                              "consumption type": "industrial",
                              "units": "",
                              "rate": "",
                              "charges rs.": "0.00"
                            },
                            {
                              "consumption type": "residential",
                              "units": "0",
                              "rate": "",
                              "charges rs.": "0.00"
                            },
                            {
                              "consumption type": "commercial",
                              "units": "",
                              "rate": "",
                              "charges rs.": "0.00"
                            }
                          ]
                    }
                    const consumptionTypeObj = category && obj["consumptionType"]?.find(item => item["consumption type"].toLowerCase() === category?.value?.toLowerCase());
                    if (consumptionTypeObj) {
                        value = (consumptionTypeObj[fieldName]);
                    }
                }
                else if (value !== null && typeof value === 'object') {
                    value = value?.value;
                }
                values.push((value === undefined || value === null || value === "") ? "" : value);
            } else {
                values.push(null);
            }
        });
        // console.log(values);
        // console.log(fieldNames);
        return { values };
    }

    const processCompletedJob = async (jobData, jobId, stateCode, discomCode, consumerType) => {
        // console.log('entry processCompletedJob');
        const { values } = extractKeysAndValues(jobData);
        // console.log(values);
        // console.log(jobData);
        const requiredFields = ["meterNo", "consumerName", "gstin", "readingDate", "units", "previousReadingDate", "consumption", "totalConsumption", "billedDemand", "rate", "totalCurrentBill", "totalBillAmount", "todSlot"];
        if (requiredFields.every(field => jobData[field] !== "")) {

            const data = {
                "buyerId": localStorage.getItem("userId"),
                "stateCode": stateCode,
                "discomCode": discomCode,
                consumerType: consumerType,
                "billData": [field, fieldNames, units, values],
                "billUploadSoruce": "ocr",
                "status": "ADDED"
            };

            const response = await uploadExcelData(`${process.env.REACT_APP_API_URL}/bill/processRawData`, data);
            // console.log(response, 'response');
            if (response.statusCode == 201) {
                setSuccesfullJobLength(prevValue => prevValue + 1);
                trackJobId++;

                const uploadData = {
                    data: {
                        "status": "ADDED"
                    }
                }
                const response = await updateData(`${process.env.REACT_APP_API_URL}/ocr/${jobId}`, uploadData);
                toast.success('Bill processed succesfully please check My Data', {
                    position: "top-center",
                    autoClose: 3000
                })

            } else {
                setJobErrorMessage("Error in data processing of bill please ");
                toast.error("Error in data processing of bill please process bill in Compare OCR Bills");

                setErrorLength(prevValue => prevValue + 1);
                trackJobId++;

            }

        } else {
            setJobErrorMessage("Error in data processing of bill please ");
            toast.error("Error in data processing of bill please process bill in Compare OCR Bills");
            setErrorLength(prevValue => prevValue + 1);
            trackJobId++;

        }
        // console.log(trackJobId, 'trackJobId');
        // console.log(trackJobIdLength, 'trackJobIdLength');
        if (trackJobId === trackJobIdLength) {
            setHelpHext(true)
        }
    };


    async function uploadFiles(jobIdsToUpload, files) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const jobId = jobIdsToUpload[i]?._id;
            // console.log(files, 'files');
            try {

                let data;
                if (typeof file === 'string') {
                    data = {
                        url: file,
                        objectId: jobId,
                        objectType: "ocr"
                    };
                } else {
                    data = {
                        file: file,
                        objectId: jobId,
                        objectType: "ocr"
                    };
                }

                const billsUploadMedia = await uploadOcrImage(`${process.env.REACT_APP_API_URL}/media`, data);
                console.log(`Uploaded file ${file.name} for jobId ${jobId}`);

                // Process billsUploadMedia as needed
            } catch (error) {
                console.error(`Error uploading file ${file.name} for jobId ${jobId}: ${error.message}`);
            }
        }
    }



    // to process jobIds till getting status completed
    async function processJobIds(jobIds, files, stateCode, discomCode, consumerType) {
        // console.log(files);
        // let parsedJobIds = jobIds;
        let parsedJobIds = JSON.parse(jobIds);
        setJobIdLength(0);
        setSuccesfullJobLength(0);
        setHelpHext(false);
        setErrorLength(0);
        setJobIdLength(parsedJobIds.length);
        trackJobIdLength = parsedJobIds.length;
        let counter = 0;
        const maxAttempts = 10;
        const intervalTime = 20000;

        const processInterval = async () => {
            if (counter >= maxAttempts) {
                return;
            }

            try {
                const data = { "jobId": parsedJobIds }
                const response = await postOcrList(`${process.env.REACT_APP_API_URL}/ocr/list`, data);
                // console.log('API call successful:', response);

                let allCompleted = true;
                let jobIdsToUpload = parsedJobIds.map(jobId => ({ jobId, _id: '' }));
                let allIdsFilled = false;
                for (const [index, job] of response?.data?.results?.entries()) {
                    if (counter === 0) {

                        const index = parsedJobIds.findIndex(id => id === job.jobId);
                        if (index !== -1) {
                            // Set the _id for the matching job in jobIdsToUpload
                            jobIdsToUpload[index]._id = job._id ? job._id : null;
                        }
                        allIdsFilled = jobIdsToUpload.every(job => job._id !== '');

                        // console.log(jobIdsToUpload);
                        if (allIdsFilled) {
                            await uploadFiles(jobIdsToUpload, files);
                        }
                    }

                    // console.log('Job status:', job.data.status);
                    if (job.data.status === 'COMPLETED') {
                        await processCompletedJob(job.data, job.jobId, stateCode, discomCode, consumerType);
                    } else {
                        allCompleted = false;
                    }
                }

                // const allCompleted = response?.data?.results?.every(job => {
                //     console.log(job);
                //     console.log('Job status:', job.data.status);
                //     if (job.data.status == 'COMPLETED') {
                //         // console.log('COMPLETEDEntry');
                //         // console.log(job);
                //         processCompletedJob(job.data, job.jobId);
                //     }
                //     return job.data.status == 'COMPLETED';
                // });

                if (allCompleted) {
                    setConsumerType('');
                    return;
                }

                const inProgressJobs = response.data.results?.filter(job => job.data.status == 'INPROGRESS');
                parsedJobIds = inProgressJobs.map(job => job.jobId);
                if (parsedJobIds.length === 0) {
                    return;
                }
            } catch (error) {
                console.error('API call failed:', error);
            }

            counter++;
            // console.log('Attempt:', counter);

            setTimeout(processInterval, intervalTime);
        };
        processInterval();
    }


    return (
        <JobProgressContext.Provider value={{
            succesfullJobLength,
            jobErrorMessage,
            jobIdLength,
            extractKeysAndValues,
            processCompletedJob,
            processJobIds,
            errorLength,
            helpText,
            field,
            fieldNames,
            units,
            discomCode,
            setDiscomCode,
            consumerType,
            setConsumerType
        }}>
            {children}
        </JobProgressContext.Provider>
    );
};
