import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
import GoTODashboard from "../../components/button/GoTODashboard";

const NoAccessPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };


  const { setIsSidebarOpen, isSidebarOpen } = useAuth();

  return (
    <div className='max-w-[1920px] mt-[100px] m-auto flex'>
      <div className='hidden sm:block'>
        <Sidebar />
      </div>
      <div className={`${isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"} w-full flex flex-col justify-center items-center transition-width duration-300 p-4`}>
        <div className='mb-4 sm:mb-7 w-fit ml-auto pr-[40px]'>
         <GoTODashboard text={' Go TO Dashboard'}/>
        </div>
        {/* <div className='w-full ml-[300px] flex justify-center items-center'> */}
        <div className="flex flex-col items-center justify-center min-h-[500px]  text-gray-800">
      {/* Red Circle with X Icon */}
      <div className="mb-4">
        <FontAwesomeIcon icon={faTimesCircle} className="text-red-600" size="6x" />
      </div>
      
      {/* Title */}
      <h1 className="text-3xl font-bold mb-2">Access Denied</h1>
      
      {/* Message */}
      <p className="text-lg text-gray-600 mb-6">
        You do not have permission to view this page.
      </p>
      
      {/* Button to go back */}
      <button
        onClick={handleGoHome}
        className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-300 focus:outline-none"
      >
        Go to Homepage
      </button>
    </div>
      </div>
    </div>
  )

};

export default NoAccessPage;
