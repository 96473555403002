import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
import { getData, postData, updateData } from "../../api/apiDataExchange";
import jwtDecode from "jwt-decode";
import { Navigate, useNavigate } from "react-router-dom";

const ManageUsers = () => {
  const { isSidebarOpen } = useAuth();
  const navigate = useNavigate();
  const tableHeaders = {
    id: "_id",
    username: "Username",
    email: "Email",
    role: "Role",
    action: "Action",
  };

  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const totalCount = users.length;

  const handleSetPageLimit = (e) => {
    setLimit(parseInt(e.target.value, 10));
  };

  const renderSortArrow = () => <span></span>;

  const [selectedUser, setSelectedUser] = useState(null);
  const [showFactoryPopup, setShowFactoryPopup] = useState(false);

  const [factories, setFactories] = useState([""]);

  const toggleFactory = (factory) => {
    let updatedFactories;

    if (Array.isArray(selectedUser.factories)) {
      if (
        selectedUser.factories.some((f) => f.factoryId === factory.factoryId)
      ) {
        updatedFactories = selectedUser.factories.filter(
          (f) => f.factoryId !== factory.factoryId
        );
      } else {
        updatedFactories = [...selectedUser.factories, factory];
      }
    } else {
      updatedFactories = [factory];
    }
    setSelectedUser({ ...selectedUser, factories: updatedFactories });
    let updatedUser = { ...selectedUser, factories: updatedFactories };
  };

  const savefactories = async () => {
    const updatedUser = removeFactoryName(selectedUser);
    const updatedData = await updateData(
      `${process.env.REACT_APP_API_URL}/user/${updatedUser["_id"]}`,
      { factories: updatedUser["factories"] }
    );
    fetchData();
    setShowFactoryPopup(false);
  };

  const removeFactoryName = (selectedUser) => {
    if (selectedUser.factories && Array.isArray(selectedUser.factories)) {
      selectedUser.factories = selectedUser.factories.map((factory) => {
        const { factoryName, ...factoryWithoutName } = factory;
        return factoryWithoutName;
      });
    }
    return selectedUser;
  };

  const fetchData = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.result?.token;
    let decodedToken = "";
    if (token) {
      decodedToken = jwtDecode(token);
      const organisationId = decodedToken.organisationId;

      const payload = {
        page: currentPage,
        pageSize: limit,
        organisationId,
        //     sortBy: sortConfig.key,
        //     sortOrder: sortConfig.direction,
      };

      const userList = await postData(
        `${process.env.REACT_APP_API_URL}/user/list`,
        payload
      );
      const data = userList?.data?.data?.results;
      setUsers(data);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, limit]);

  useEffect(() => {
    const fetchData = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user?.result?.token;
      let decodedToken = "";
      if (token) {
        decodedToken = jwtDecode(token);
        const organisationId = decodedToken.organisationId;
        const organisationData = await getData(
          `${process.env.REACT_APP_API_URL}/organisation/tree/${organisationId}`
        );
        const data = getFactoryIdAndNameArray(organisationData?.data);
        setFactories(data);
      }
    };
    fetchData();
  }, [currentPage, limit]);

  const getFactoryIdAndNameArray = (data) => {
    if (data && data.factories && Array.isArray(data.factories)) {
      return data.factories.map((factory) => ({
        factoryId: factory._id,
        factoryName: factory.name,
      }));
    } else {
      return [];
    }
  };
  return (
    <>
      <div className="max-w-[1920px] mt-[100px] m-auto flex">
        <div className="hidden sm:block">
          <Sidebar />
        </div>
        <div
          className={`${
            isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"
          } w-full flex flex-col justify-center items-center transition-width duration-300 p-4`}
        >
          <div className="w-full ">
            <div className="p-2 text-semibold text-lg">Manage Users</div>
            <div className="overflow-x-auto">
              <table className="w-full mt-2 shadow  min-h-[200px] border-[#EFEFEF] ">
                <thead className="bg-[#EFEFEF] text-left sticky top-0 z-10">
                  <tr>
                    {Object.entries(tableHeaders).map(([key, value], index) => (
                      <th
                        key={index}
                        className="cursor-pointer font-medium w-[10%] px-4 py-2"
                      >
                        {value} {renderSortArrow()}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className=" ">
                  {users.length === 0 ? (
                    <tr>
                      <td
                        colSpan={Object.keys(tableHeaders).length}
                        className="px-6 py-4 text-sm leading-5 text-gray-500"
                      >
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    users.map((rowData, rowIndex) => (
                      <tr key={rowIndex} className=" text-gray-800 font-thin">
                        {Object.entries(tableHeaders).map(
                          ([key, header], cellIndex) => {
                            if (key === "action") {
                              return (
                                <td
                                  key={cellIndex}
                                  className="p-1 py-3 w-[10%]"
                                >
                                  {rowData?.userType?.[0] !== "admin" && (
                                    <button
                                      className="cursor-pointer text-[blue] underline px-3 py-1"
                                      onClick={() => {
                                        navigate(`/userrole?userid=${rowData['_id']}`)
                                      }}
                                    >
                                      Assign Role
                                    </button>
                                  )}
                                </td>
                              );
                            }

                            if (key === "role") {
                              return (
                                <td key={cellIndex} className="p-1 w-[10%]">
                                  <button className="cursor-pointer uppercase  px-3 py-1">
                                    {rowData?.["userType"]?.[0]}
                                  </button>
                                </td>
                              );
                            }
                            const value = rowData[header.toLowerCase()];

                            return (
                              <td key={cellIndex} className="p-1 w-[10%]">
                                {value}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* <div className="overflow-y-auto" style={{ height: "200px" }}>
              <table className="w-full mt-2 ">
            
              </table>
            </div> */}

            {users.length !== 0 && (
              <div className="pagination-controls border-t-2 border-[#abaaaa] flex items-center justify-between pt-1">
                <div className="mr-6">
                  <label htmlFor="itemsPerPage" className="mr-2">
                    Items per page:
                  </label>
                  <select
                    id="itemsPerPage"
                    value={limit}
                    onChange={handleSetPageLimit}
                    className="px-2 py-1 rounded-lg border border-gray-300"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
                <div>
                  <button
                    className="bg-[#e1e0e0] px-2 py-1 rounded-md"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span className="mx-4">
                    {totalCount > 0
                      ? `${(currentPage - 1) * limit + 1} to ${Math.min(
                          currentPage * limit,
                          totalCount
                        )} of ${totalCount}`
                      : "0"}
                  </span>
                  <button
                    className="bg-[#e1e0e0] px-2 py-1 rounded-md"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage * limit >= totalCount}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showFactoryPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md  w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">Assign Factories</h3>
            <div className="flex flex-col space-y-2 max-h-[240px] overflow-y-scroll mb-4">
              {factories.map((factory) => (
                <label key={factory} className="flex items-center">
                  <input
                    type="checkbox"
                    onChange={() => toggleFactory(factory)}
                    checked={
                      selectedUser?.factories?.some(
                        (assignedFactory) =>
                          assignedFactory.factoryId === factory.factoryId
                      ) || false
                    }
                    className="mr-2"
                  />
                  {factory?.factoryName}
                </label>
              ))}
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowFactoryPopup(false)}
                className="px-4 py-2 bg-gray-300 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={savefactories}
                className="px-4 py-2 bg-green-500 text-white rounded-md"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageUsers;
