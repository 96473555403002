import React, { useEffect, useState } from "react";
import LineChartEnergyView2Y from "../../containers/graphs/LineChartEnergyView2Y";
import { ConstantUtils } from "../../utils/ConstantUtils";
import { BillConsumptionService } from "../../services/graphs/billConsumptionService";
import { getData, postData } from "../../api/apiDataExchange";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoaderComponent from "../loaderCompent/LoaderComponent";
import LegendComponent from "../chart/LegendComponent";

const YearlyTodView = ({
  selectedOptions,
  organisationList,
  setSelectedOptions,
  selectedOrg,
  view,
  setSelectedOrg,
  selectedYear,
  setSelectedMeterOptions,
  setSelectedMeter,
  setSelectedYear,
  meterList,
  selectedMeterOptions,
  isLoading,
  data,
  slotConfigData
}) => {

  const billService = new BillConsumptionService();


  const [selectedFactOptions, setSelectedFactOptions] = useState([]);
  const [selectedUnitOptions, setSelectedUnitOptions] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState("orgs");




  const [months, setMonths] = useState([
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ]);


  
//  // console.log(unitsByMonth);
  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
        legend: {
          display: false,
        },
      },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: selectedYear?.getFullYear(),
        },
        position: "bottom",
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: view === "TodEnergyView" ? "Energy (kwh)" : "Amount (Rs)",
        },
        grid: {
          display: false,
        },


        ticks: {
        callback: function(value) {
          if (value >= 1000) {
            return value / 1000 + 'k'; // Converts 1000 to 1k, 2000 to 2k, etc.
          }
          return value;
        }
      }
      },
      // y1: {
      //     type: "linear",
      //     display: true,
      //     position: "right",
      //     grid: {
      //         drawOnChartArea: false,
      //         display: false,
      //     },
      //     title: {
      //         display: true,
      //         text: "kva", // Add your label here
      //     },
      // },
    },
    elements: {
      line: {
        tension: 0.4, // This will make the line curve
      },
    },
  });

  function getMonthName(monthIndex) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthIndex];
  }



  const handleOrgChange = (selected) => {
    setSelectedOptions(selected);
    setSelectedOrg({
      label: selected.label,
      value: selected.value,
    });
  };

  const handleMeterChange = (selected) => {
    setSelectedMeterOptions(selected);
//    console.log(selected);
    setSelectedMeter({
      label: selected.label,
      value: selected.value,
    });
  };


//   useEffect(() => {
// console.log({slotConfigData})
//   }, [slotConfigData])
  


  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontSize: "10px",
      borderRadius: "20px ",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "10px",
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "10px", // Setting border radius to 20px
    }),
  };

  const CheckboxOption = (props) => {
    return (
      <components.Option {...props}>
        {/* <input
                    // type="checkbox"
                    // checked={props.isSelected}
                    onChange={() => null}
                    // onClick={(e) => e.stopPropagation()}
                    readOnly
                />{' '} */}
        <label style={{ marginLeft: "8px" }}>{props.label}</label>
      </components.Option>
    );
  };

  const handleSelectYear = (date) => {
    const year = date.getFullYear();
    const newDate = new Date(year, 0);
    setSelectedYear(newDate);
  };
//  // console.log(selectedOptions);
//  // console.log(selectedMeterOptions);
  return (
    <div className="">
      <div
        className={`${
          selectedFilter === "orgs" ? "" : "mb-[10px]"
        }   mb-2 sm:grid sm:grid-cols-3 lg:grid-cols-3 sm:gap-[10px] px-[20px] sm:px-0 justify-end`}
      >
        <div className={`w-[100%]`}>
          <Select
            components={{ Option: CheckboxOption }}
            // isMulti
            options={organisationList?.map((org) => ({
              label: org.name,
              value: org._id,
            }))}
            onChange={handleOrgChange}
            value={selectedOptions}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            isClearable={false}
            placeholder="Select Organisation"
            classNamePrefix="custom-select"
            styles={customStyles}
            // controlShouldRenderValue={false}
          />
        </div>

    

        <div className={`w-[100%] my-2 sm:my-0`}>
          <Select
            components={{ Option: CheckboxOption }}
            // isMulti
            options={meterList?.map((org) => ({
              label: org.consumerNo,
              value: org._id,
            }))}
            onChange={handleMeterChange}
            value={selectedMeterOptions}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            isClearable={false}
            placeholder="Select Consumer No"
            classNamePrefix="custom-select"
            styles={customStyles}
            // controlShouldRenderValue={false}
          />
        </div>
        <div className={`w-[100%] yearly-tod-view`}>
          <DatePicker
            selected={selectedYear}
            onChange={handleSelectYear}
            dateFormat="yyyy"
            showYearPicker
            wrapperClassName="year-picker-wrapper"
            placeholderText="Start Year"
            popperPlacement="auto"
            className="hide-dropdown-icon"
          />
        </div>

        {/* <div className="date-range-picker-container relative custom"> */}
        {/* <input
                        type="text"
                        value={`${selectionRange.startDate.toDateString()} - ${selectionRange.endDate.toDateString()}`}
                        onClick={handleInputClick}
                        readOnly
                        className="date-range-input rounded-[10px] text-[10px] w-[250px] leading-[20px] h-[36px] px-[5px]"
                    /> */}
        {/* {isCalendarOpen && (
                        <div className="absolute bg-black shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] right-10 mt-2" ref={dateRangePickerRef}>
                            <DateRangePicker
                                ranges={[selectionRange]}
                                onChange={handleSelect}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                showMonthAndYearPickers={true}
                                direction="horizontal"
                                className="date-range-picker"
                                editableDateInputs={true}
                                inputRanges={[]}
                                onClose={() => setIsCalendarOpen(false)}
                            />
                        </div>
                    )} */}
        {/* </div> */}
      </div>
      <div className="bg-[white] sm:shadow-[0_4px_4px_0_#00000040] sm:min-h-[400px] rounded-[10px] h-full w-full sm:px-[35px] sm:py-[25px]">
        {isLoading ? (
          <div className="w-full h-full flex justify-center mt-[100px] items-center">
            <LoaderComponent />
          </div>
        ) : (
            <>
           {Object.keys(slotConfigData).length > 0 ? <div className="sm:pt-6 "><LegendComponent slotConfigData={slotConfigData} data={data} columns={"grid-cols-6"}  /></div  >:<div className="sm:pt-12"></div>} 
          <LineChartEnergyView2Y data={data} options={options} />
          </>
        )}
      </div>
    </div>
  );
};

export default YearlyTodView;
