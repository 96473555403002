export class GraphUtils {
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Define a function to generate random data for a given month
  generateMonthData() {
    return {
      B1: Array.from({ length: 6 }, () => this.getRandomInt(100, 300)),
      G1: Array.from({ length: 6 }, () => this.getRandomInt(100, 300)),
      Y: Array.from({ length: 6 }, () => this.getRandomInt(100, 300)),
      G2: Array.from({ length: 6 }, () => this.getRandomInt(100, 300)),
      R: Array.from({ length: 6 }, () => this.getRandomInt(100, 300)),
      B2: Array.from({ length: 6 }, () => this.getRandomInt(100, 300)),
      "contract demand": Array.from({ length: 6 }, () =>
        this.getRandomInt(400, 400)
      ),
      "Peak Demand": Array.from({ length: 6 }, () =>
        this.getRandomInt(500, 500)
      ),
      "% contract demand": Array.from({ length: 6 }, () =>
        this.getRandomInt(600, 600)
      ),
      "L F": Array.from({ length: 6 }, () => this.getRandomInt(100, 300)),
      Demand: Array.from({ length: 6 }, () => this.getRandomInt(100, 300)),
    };
  }

  // Define a function to generate data for all months of a given year
  generateYearData(year) {
    let data = {};
    const months = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
    months.forEach((month) => {
      data[month] = this.generateMonthData();
    });
    return { [year]: data };
  }

  // Generate data for multiple years
  generateTodDataForYears(...years) {
    let result = {};
    years.forEach((year) => {
      result = { ...result, ...this.generateYearData(year) };
    });
    return result;
  }

  generateMonthlyEnergyData(initialLimit, finalLimit, years) {
    const months = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];

    const data = {};

    for (let year of years) {
      data[year] = {};
      for (let month of months) {
        data[year][month] = [];
        for (let i = 0; i < 12; i++) {
          data[year][month].push(
            Math.floor(Math.random() * (finalLimit - initialLimit + 1)) +
              initialLimit
          );
        }
      }
    }

    return data;
  }

  generateRandomEnergyData(years, numMonths, minConsumption, maxConsumption) {
    let data = {};
    years.forEach((year) => {
      data[year.toString()] = Array.from(
        { length: numMonths },
        () =>
          Math.floor(Math.random() * (maxConsumption - minConsumption + 1)) +
          minConsumption
      );
    });
    return data;
  }

  formatYaxesLabels(value, index, ticks) {
    if (value >= 1e9) {
      return (value / 1e9).toFixed() + "B";
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed() + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(1) + "K";
    } else {
      return value;
    }
  }


  fomatSlotName(key,allSlotTimeData) {
    // console.log({key,allSlotTimeData});
    let slotNames = allSlotTimeData.slotName;
    let slots = allSlotTimeData.slot;

    // Find the slot which contains the key's time range
    for (let slotKey in slots) {
        for (let timeRange of slots[slotKey]) {
            if (slotNames[key] === timeRange) {
                console.log({slotNames:slotNames[key], timeRange})
                if(slotKey.includes('-')){
                    return `ToD-${slotKey.split('-')[1]}`;
                }else{
                    return slotKey;
                }
                
            }
        }
    }
    return key;
  }
  fomatSlotNameWithTimeLabels(key,slotlables,slotConfigData) {
    console.log({slotConfigData})
    // console.log({ key, slotlables, slotConfigData });
    const timeLabels = slotConfigData.slotTimeLabels;
    const slotNames = slotConfigData.slotName;
    const slotKeys = Object.keys(slotNames);
    // Find the slot number that contains the key
    let slotNumber;
    for (const slotKey of slotKeys) {
      if (slotKey === key) {
        const timeRange = slotNames[slotKey];
        for (const [slot, ranges] of Object.entries(slotConfigData.slot)) {
          if (ranges.includes(timeRange)) {
            slotNumber = slot;
            break;
          }
        }
        break;
      }
    }
    if (!slotNumber) {
      return ""; 
    }
    let todLabel;
    switch (slotNumber) {
      case "slot-1":
        todLabel = "ToD1";
        break;
      case "slot-2":
        todLabel = "ToD2";
        break;
      case "slot-3":
        todLabel = "ToD3";
        break;
      case "slot-4":
        todLabel = "ToD4";
        break;
      case "slot-5":
        todLabel = "ToD5";
        break;
      case "slot-6":
        todLabel = "ToD6";

        break;
        case "RTC":
            todLabel = "RTC";
            break;
      default:
        return key; // Return the key if slotNumber is not recognized
    }
    console.log({values:`${todLabel} (${timeLabels[key]})`})
    return `${todLabel} (${timeLabels[key]})`;
  }
}
