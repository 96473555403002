export const UnitPopup = ({ units, onClose, onViewBills }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg shadow-lg  w-3/4 md:w-[50%]">
          <div className="flex justify-between px-6 py-4  rounded-lg items-center ">
            <h2 className="text-xl font-bold">Unit List</h2>
            <div
                className="text-red-500 cursor-pointer"
                onClick={onClose}
            >
              ✕
            </div>
          </div>
           <hr className="pb-2"/>
          <ul  className="space-y-4 max-h-[300px] upload-bills text-left px-6 overflow-y-scroll">
            {units.map((unit) => (
              <li
                key={unit._id}
                className="flex justify-between items-center border-b pb-2"
              >
                <span className="text-gray-700 uppercase font-medium">
                  {unit.name}
                </span>
                <div
                  className="text-blue-500 cursor-pointer hover:text-blue-700 underline"
                  onClick={() => onViewBills(unit)}
                >
                  View Bills
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };
  