import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
import { UnitService } from "../../services/unit/UnitService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
  faClone,
  faCogs,
  faDatabase,
  faEdit,
  faFileEdit,
  faGear,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AnalyticsSidebar from "../../components/sidebar/AnalyticsSidebar";
import GoTODashboard from "../../components/button/GoTODashboard";
import { Tooltip } from "react-tooltip";
import CustomToolTipICon from "../../components/tooltips/CustomToolTipICon";
import BackButton from "../../components/backButton/BackButton";
import { getUnitsandMeters, getUserType } from "../../utils/authenticationUtils";

const unitservice = new UnitService();
const UnitList = () => {
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [meters, setMeters] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [unitListExcludingselectedUnit, setUnitListExcludingselectedUnit] =
    useState([]);
    const [page, setPage] = useState(1); // Current page
    const [pageSize, setPageSize] = useState(5); // Records per page
    const [totalPages, setTotalPages] = useState(1); // Total pages based on API response
  
  const getAllMetersData = async () => {
    
    const usertype = await getUserType();
    const data = {
        page,
        pageSize
    }
    if (usertype === 'admin') {
      data['buyerId']=localStorage.getItem("userId")
    }else{
      const {units} = await getUnitsandMeters();
      data['id']= units
    }
    
    const response = await unitservice.getAllUnitsWithBuyerId(data);
    console.log({response})
    setMeters(response.results || []);
    setTotalPages(Math.ceil(response.total / pageSize)); 
  };

    useEffect(() => {
        getAllMetersData(page, pageSize);
      }, [page, pageSize]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloneClick = (data) => {
    // console.log({ data });
    setSelectedUnit(data);
    setIsModalOpen(true);
  };

  const getunitListExcludingselectedUnit = () => {
    const unitListExcludingselectedUnit = meters.filter(
      (unit) => unit._id !== selectedUnit._id
    );
    // console.log({ unitListExcludingselectedUnit });
    return unitListExcludingselectedUnit;
  };

  useEffect(() => {
    const units = getunitListExcludingselectedUnit();
    setUnitListExcludingselectedUnit(units);
  }, [selectedUnit]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getAllMetersData();
  }, []);

  const [selectedUnits, setSelectedUnits] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedUnits((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((unitId) => unitId !== value);
      }
    });
  };

  const handleCloneData = async () => {
    // console.log(selectedUnit.settings);
    if (selectedUnit.settings === undefined) {
      toast.info("Selected Unit Has No Settings Data!", {
        position: "top-center",
      });
      return;
    }

    const response = await unitservice.cloneUnitSettingsData(
      selectedUnit,
      selectedUnits
    );
    // console.log({ response });

    if (response) {
      toast.success("Unit Cloned Successfully!", {
        position: "top-center",
      });
    } else {
      toast.success("Unit Clone failed!", {
        position: "top-center",
      });
    }
    closeModal();
  };

  useEffect(() => {
    // console.log({ selectedUnits });
  }, [selectedUnits]);

  function capitalizeWords(str) {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <div className="max-w-[1920px] relative m-auto mt-[70px] sm:mt-[100px] pb-2">
      <div className="hidden sm:block">
        {/* <AnalyticsSidebar /> */}
        <Sidebar type={2} />
      </div>
      <div
        className={`${
          isSidebarOpen ? "sm:ml-[300px]" : "sm:ml-[80px]"
        }  sm:px-[50px] px-[15px] transition-width duration-300`}
      >
        <div className={`sm:hidden flex absolute gap-2 right-0 px-[15px] `}>
          <GoTODashboard /> <BackButton  py={"py-1"}  px={"px-1"} />
        </div>
        <div className="flex  w-full items-center justify-between">
          <div className="p-4 mt-[40px] text-[20px] sm:text-[28px] font-medium leading-[42px]">
            Unit List
          </div>
          <div
            className={`hidden sm:flex justify-end sm:justify-end items-center w-full gap-4 sm:w-[160px] `}
          >
            <GoTODashboard /><BackButton smpy={"sm:py-3"} py={"py-3"}  px={"px-4"} />
          </div>

        </div>{" "}
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
  <div className="overflow-x-auto">
    <table className="w-full text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
      <thead className="text-xs uppercase bg-green-700 text-white sticky top-0 z-10">
        <tr>
          <th scope="col" className=" py-4 w-[15%]">Organisation</th>
          <th scope="col" className=" py-4 w-[35%]">Factory</th>
          <th scope="col" className="py-4 w-[30%]">Unit</th>
          <th scope="col" className="py-4 w-[20%]">Action</th>
          <th></th>
        </tr>
      </thead>
    </table>
  </div>
  <div className="max-h-[380px] overflow-y-auto">
    <table className="w-full text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
      <tbody>
        {meters.map((unit, index) => (
          <tr
            className={`border-b text-black ${
              (index + 1) % 2 === 0 ? "bg-gray-50" : "bg-gray-100"
            }`}
            key={unit.id}
          >
            <th scope="row" className="px-6 w-[15%] py-4 font-medium whitespace-nowrap">
              {capitalizeWords(unit.organisationId.name)}
            </th>
            <th scope="row" className="px-6 py-4  w-[35%] font-medium whitespace-nowrap">
              {capitalizeWords(unit.factoryId.name)}
            </th>
            <td className="px-6 w-[30%] py-4">{capitalizeWords(unit.name)}</td>
            <td className=" py-4 w-[100%] justify-center flex gap-4 ">
              <div className="">
                <Link to={`/edit-unit/${unit._id}`}>
                  <div>
                    <CustomToolTipICon
                      icon={faEdit}
                      color={"black"}
                      text={"Edit Unit Data"}
                    />
                  </div>
                </Link>
              </div>
              <div className="relative group">
                <Link to={`/update-unit-setting/${unit._id}`}>
                  <div>
                    <CustomToolTipICon
                      icon={faTools}
                      color={"black"}
                      text={"Customise Units"}
                    />
                  </div>
                </Link>
              </div>
              <div className="relative group">
                <div onClick={() => handleCloneClick(unit)}>
                  <CustomToolTipICon
                    icon={faClone}
                    color={"black"}
                    text={"Replicate customisation"}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="flex justify-between items-center  p-4">
    <div className="flex items-center gap-2 mt-4">
      <label htmlFor="pageSize" className="mr-2">Page Size:</label>
      <select
        id="pageSize"
        value={pageSize}
        onChange={(e) => {
          setPageSize(parseInt(e.target.value));
          setPage(1); // Reset to the first page when page size changes
        }}
        className="border rounded px-2 py-1"
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
      </select>
    </div>
    <div className="flex items-center gap-2 mt-4">
      <div
        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        disabled={page === 1}
        className="bg-gray-300 cursor-pointer px-2 py-1 rounded hover:bg-gray-400 disabled:opacity-50"
      >
        Previous
      </div>
      <span>Page {page} of {totalPages}</span>
      <div
        onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={page === totalPages}
        className="bg-gray-300 px-2  cursor-pointer py-1 rounded hover:bg-gray-400 disabled:opacity-50"
      >
        Next
      </div>
    </div>
  </div>
</div>

      </div>
    </div>
  );
};

export default UnitList;
