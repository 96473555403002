"use client"
import { useState, useEffect } from "react";
import { FormEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faCamera, faCircleDown } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteImageById, getImageById, uploadImage } from '../../services/apis/media';
import { createUser, sendOTP } from '../../services/apis/user';
import SignUpPopup from "../../components/popup/SignUpPopUp";
import { useLocation } from 'react-router-dom';
import { postData } from "../../api/apiDataExchange";


const SignUp = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const [affirmation, setAffirmation] = useState(false);
    const [userId, setUserId] = useState('');
    const [isUserInvited, setIsUserInvited] = useState(false);
    const [imageId, setImageId] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [photoUrl, setPhotoUrl] = useState('');
    const [govtIdUrl, setGovtIdUrl] = useState('');
    const [companyIdUrl, setCompanyIdUrl] = useState('');
    const [orgId, setOrgId] = useState();
    const location = useLocation();

    const [formData, setFormData] = useState({
        username: "",
        companyName: "",
        PAN: "",
        GST: "",
        designation: "",
        email: "",
        website: "",
        mobile: "",
        landline: "",
        password: "",
        confirmPassword: "",
        // photo: null,
        // companyId: null,
        // govtId: null
    });

    const [errors, setErrors] = useState({});

    const handleOpenPopup = () => {
        setOpenPopup(!openPopup);
    };



    useEffect(() => {
        const queryParams =  new URLSearchParams(location.search);
      
        const checkIsInvited = queryParams.get('invited');
        const userEmail = queryParams.get('email');
        const orgId = queryParams.get('orgId');
      
        if (checkIsInvited === 'true') {
            setIsUserInvited(true);
        }
        if (orgId) {
            setOrgId(orgId)
        }
        if (userEmail) {
            setFormData({
                ...formData,
                email:userEmail
            }
            )
        }
    }, [location.search])
    

    useEffect(() => {
        const getdata = async () => {
          const payload = {
            id: orgId,
          };
          const response = await postData(
            `${process.env.REACT_APP_API_URL}/organisation/list`,
            payload
          );
    
          if (response?.data?.data?.results?.length > 0) {
           console.log({orgData:response?.data?.data?.results});

           setFormData({
            ...formData,
            companyName:response?.data?.data?.results[0].name
           }
        );

          }
        };
        getdata();
      }, [orgId]);
    const handleChange = async (e) => {
        const { name, value } = e.target;
        let newErrors = { ...errors };
        // delete newErrors[name as keyof Errors];
        // if (name === 'photo' || name === 'companyId' || name === 'govtId') {
        //     if (e.target.files) {
        //         const file = e.target.files[0];
        //         console.log(file);

        //         setSelectedImage(file);
        //         if (file) {
        //             var type = 'image';
        //             var objectType = 'user';
        //             const response = await uploadImage(file, objectType, type);

        //             setImageId(response?.result?._id);

        //             const getImage = await getImageById(response?.result?._id);

        //             if (name === 'photo') {
        //                 setPhotoUrl(getImage?.result?.url);
        //             } else if (name === 'govtId') {
        //                 setGovtIdUrl(getImage?.result?.url);
        //             } else if (name === 'companyId') {
        //                 setCompanyIdUrl(getImage?.result?.url);
        //             }
        //             const imageIds = formData[name] || [];
        //             imageIds.push(response?.result?._id);
        //             setFormData({
        //                 ...formData,
        //                 [name]: imageIds
        //             });
        //         }
        //     }
        //     // formData;
        // } else {
        setFormData({
            ...formData,
            [name]: value
        });
        // }
        setErrors(newErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = {};

        if (!formData.username.trim()  ) {
            newErrors.username = "Name is required";
        }
        if (!formData.companyName.trim() ) {
            newErrors.companyName = "Company Name is required";
        }
        if (!formData.designation.trim()) {
            newErrors.designation = "Designation is required";
        }
        if (!formData.email.trim() ) {
            newErrors.email = "Company Email is required";
        }
        if (!formData.PAN.trim() && !isUserInvited) {
            newErrors.PAN = "Company PAN No is required";
        }

        if (!formData.GST.trim() && !isUserInvited) {
            newErrors.GST = "Company GST No is required";
        }
        // if (!formData.website.trim()) {
        //     newErrors.website = "Website is required";
        // }
        if (!formData.mobile.trim()) {
            newErrors.mobile = "Mobile Number is required";
        }
        // if (!formData.landline.trim()) {
        //     newErrors.landline = "Landline is required";
        // }
        if (!formData.password.trim()) {
            newErrors.password = "Password is required";
        }
        if (!formData.confirmPassword.trim()) {
            newErrors.confirmPassword = "Confirm Password is required";
        }

        if(formData.password !== formData.confirmPassword){
            newErrors.confirmPassword = "Password and confirm Password  is Different";
        }
        // if (!formData.photo) {
        //     newErrors.photo = "Photo is required";
        // }
        // if (!formData.govtId) {
        //     newErrors.govtId = "Photo Id is required";
        // }
        // if (!formData.companyId) {
        //     newErrors.companyId = "Company I-Card is required";
        // }

        setErrors(newErrors);
        console.log(newErrors);
        if (Object.keys(newErrors).length === 0) {

            let payload;
            if (isUserInvited) {
                payload = {
                    ...formData,
                    status: 'active',
                    isUserInvited,
                    userType:['user'],
                    orgId
                };
            }else{
                payload = {
                    ...formData,
                    status: 'active',
                    isUserInvited,
                    userType:['admin']
                };
            }
          
            try {
                const response = await createUser(payload);
            

                if (response.success) {
                    // setFormData({
                    //     username: "",
                    //     companyName: "",
                    //     designation: "",
                    //     email: "",
                    //     website: "",
                    //     mobile: "",
                    //     landline: "",
                    //     password: "",
                    //     confirmPassword: "",
                    //     photo: null,
                    //     companyId: null,
                    //     govtId: null
                    // })
                    setUserId(response?.result?._id)
                    setErrors({});
                    setOpenPopup(true);

                    try {
                        const otpResponse = await sendOTP(response?.result?._id);
                        if (otpResponse.success) {
                            toast.success('OTP Sent.', {
                                position: "top-center"
                            });
                        } else {
                            console.error("Error sending OTP:", otpResponse.error);
                        }
                    } catch (error) {
                        console.error("Error sending OTP:", error);
                    }

                } else {
                    if(response['response']['data']['error']['message']){
                    console.error("Error creating user:",response['response']['data']['error']['message']);
                    toast.error(response['response']['data']['error']['message'], {
                        position: "top-center"
                    });
                }
                }
            } catch (error) {
                console.error("Error creating user:", error);
                toast.error('Error creating user. Please try again later.', {
                    position: "top-center"
                });

            }

        } else {
            console.log("Form submission failed. Please fix the errors.");
            // toast.error('Please fix the form errors before submitting.', {
            //     position:"top-center"
            // });
        }
    };

    return (
        <>
            <div className="max-w-[1800px] m-auto ">
                <div className="mt-[36px] md:mt-[27px] sm:mx-[20px]  xl:mx-[177px] pb-[32px] md:pb-[50px] xl:mt-[68px] bg-[#E2F5EC] rounded-[20px] shadow-md flex  justify-center ">
                    <div className="w-full p-4 sm:p-0">
                        <div id="titleText" className="text-[#067C4E] mt-[31px]   md:mt-[38px] xl:mt-[41px] text-[24px] font-medium leading-[36px] text-center">
                            Be a Part of Us
                        </div>
                        <div className="text-[14px] font-normal leading-snug text-center text-black">
                            Join Us and Be a Part of Something Green!
                        </div>

                        {/* <div className="flex justify-center mt-[32px]  xl:mt-[36px]">
                            <div className="w-[50px] h-[50px] bg-[#B7E4C7] font-bold  rounded-full   text-[#067C4E] flex justify-center items-center">
                                1
                            </div>
                            <div className="flex items-center">
                                <img className="w-[50px] px-[5px]" src="/Arrow1.svg" />
                            </div>
                            <div className="w-[50px] h-[50px] bg-[#fff] border font-bold border-[#067C4E]  text-[#067C4E] rounded-full justify-center flex items-center">
                                2
                            </div>
                        </div> */}

                        <div className="mt-[32px] w-full md:px-[115px] xl:px-[184px]">
                            <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                Name<span className="text-red-600"> *</span>
                            </div>
                            <div className="mt-[12px]">
                                <input
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    placeholder="Enter Name"
                                    className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                                    type="text"
                                />
                                <br />
                                {errors.username && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.username}</p>}
                            </div>
                        </div>
                        <div className={` mt-[32px] w-full md:px-[115px] xl:px-[184px]`}>
                            <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                Company Name<span className="text-red-600"> *</span>
                            </div>
                            <div className="mt-[12px]">
                                <input
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    placeholder="Enter Company Name"
                                    disabled={isUserInvited}
                                    className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                                    type="text"
                                />
                                <br />
                                {errors.companyName && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.companyName}</p>}
                            </div>
                        </div>
                        <div className={`${isUserInvited ? " hidden ":" md:flex " }  md:px-[115px] md:gap-[50px] xl:px-[184px]`}>
                            <div className="mt-[32px] w-full">
                                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                    PAN<span className="text-red-600"> *</span>
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        name="PAN"
                                        value={formData.PAN}
                                        placeholder="Enter Company PAN"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="text"
                                        onChange={handleChange}
                                    />
                                    <br />
                                    {errors.PAN && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.PAN}</p>}
                                </div>
                            </div>
                            <div className="mt-[32px] w-full ">
                                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                    GST
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        name="GST"
                                        value={formData.GST}
                                        placeholder="Enter Company GST"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="text"
                                        onChange={handleChange}
                                    />
                                        <br />
                                        {errors.GST && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.GST}</p>}
                                </div>
                            </div>
                        </div>
         
         
          
                        <div className="mt-[32px] w-full md:px-[115px] xl:px-[184px]">
                            <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                Designation<span className="text-red-600"> *</span>
                            </div>
                            <div className="mt-[12px]">
                                <input
                                    name="designation"
                                    value={formData.designation}
                                    onChange={handleChange}
                                    placeholder="Enter Designation"
                                    className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                                    type="text"
                                />
                                <br />
                                {errors.designation && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.designation}</p>}
                            </div>
                        </div>
                        <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px]">
                            <div className="mt-[32px] w-full">
                                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Company Email<span className="text-red-600"> *</span>
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        name="email"
                                        value={formData.email}
                                        placeholder="Enter Company Email"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="email"
                                        disabled ={isUserInvited}
                                        onChange={handleChange}
                                    />
                                    <br />
                                    {errors.email && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.email}</p>}
                                </div>
                            </div>
                            <div className= {`${isUserInvited ? "hidden ":"relative " } mt-[32px] w-full `}>
                                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Website (Same as Email Domain)
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        name="website"
                                        value={formData.website}
                                        placeholder="Enter Website"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="text"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px]">
                            <div className="mt-[32px] w-full ">
                                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Mobile<span className="text-red-600"> *</span>
                                </div>
                                <div className="mt-[12px] mobile-input">
                                    <input
                                        name="mobile"
                                        value={formData.mobile}
                                        placeholder="Enter Mobile Number"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9] no-spinner"
                                        type="number"
                                        onChange={handleChange}
                                    />
                                    {errors.mobile && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.mobile}</p>}
                                </div>
                            </div>
                            <div className= {`${isUserInvited ? "hidden ":"relative " } mt-[32px] w-full`}>
                                <div className="text-black text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Company Landline Number
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        name="landline"
                                        value={formData.landline}
                                        placeholder="Enter Company Landline Number"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="email"
                                        onChange={handleChange}
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px]">
                            <div className="mt-[32px] w-full">
                                <div className="text-black text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Password<span className="text-red-600"> *</span>
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        name="password"
                                        placeholder="Enter Password"
                                        className="input-placeholder w-full border pl-[20px]  rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    {errors.password && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.password}</p>}
                                </div>
                            </div>
                            <div className="mt-[32px] w-full">
                                <div className="text-black  text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Confirm Password<span className="text-red-600"> *</span>
                                </div>
                                <div className="mt-[12px]">
                                    <input
                                        name="confirmPassword"
                                        placeholder="Enter Password Again"
                                        className="input-placeholder w-full border pl-[20px] rounded-[10px] h-[50px] border-[#B9B9B9]"
                                        type="password"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                    />
                                    {errors.confirmPassword && <p className="text-red-500 m-[10px] mt-[1px] absolute">{errors.confirmPassword}</p>}
                                </div>
                            </div>
                        </div>
                        {/* <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px] mt-[42px] w-full">
                            <div className="text-black text-[14px] leading-[21px] font-normal ml-[35%] md:ml-[10px]">
                                Upload Photo<span className="text-red-600"> *</span>
                            </div>
                            <div className="ml-[30%] md:ml-[300px] mt-2 md:mt-0 flex items-center absolute">
                                <label htmlFor="photo-upload" className="cursor-pointer mr-4">
                                    <FontAwesomeIcon icon={faArrowUpFromBracket} className="h-6 w-6 text-[#067C4E]" />
                                </label>
                                <span className="mr-4">OR</span>
                                <label htmlFor="camera-upload" className="cursor-pointer mr-4">
                                    <FontAwesomeIcon icon={faCamera} className="h-6 w-6 text-[#067C4E]" />
                                </label>
                                <input
                                    name="photo"
                                    id="photo-upload"
                                    className="hidden"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleChange}
                                />
                                <input
                                    name="photo"
                                    id="camera-upload"
                                    className="hidden"
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    onChange={handleChange}
                                />
                            </div>
                            {photoUrl && (
                                <img className="w-[5%] h-[7%] md:ml-[550px] absolute" src={photoUrl} alt="" />
                            )}
                            {errors.photo && <p className="text-red-500 ml-[25%] md:ml-[450px] mt-[28px] md:mt-[2px] absolute">{errors.photo}</p>}
                        </div>
                        <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px] mt-[65px] md:mt-[42px] w-full">
                            <div className="text-black text-[14px] leading-[21px] font-normal ml-[33%] md:ml-[10px]">
                                Upload Photo ID<span className="text-red-600"> *</span>
                            </div>
                            <div className="ml-[30%] mt-2 md:mt-0 md:ml-[300px] flex items-center absolute">
                                <label htmlFor="govtId-photo-upload" className="cursor-pointer mr-4">
                                    <FontAwesomeIcon icon={faArrowUpFromBracket} className="h-6 w-6 text-[#067C4E]" />
                                </label>
                                <span className="mr-4">OR</span>
                                <label htmlFor="govtId-camera-upload" className="cursor-pointer mr-4">
                                    <FontAwesomeIcon icon={faCamera} className="h-6 w-6 text-[#067C4E]" />
                                </label>
                                <input
                                    name="govtId"
                                    id="govtId-photo-upload"
                                    className="hidden"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleChange}
                                />
                                <input
                                    name="govtId"
                                    id="govtId-camera-upload"
                                    className="hidden"
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    onChange={handleChange}
                                />
                            </div>
                            <p className="text-black text-[12px] leading-[21px] font-normal mt-[35px] ml-[16%] md:ml-[250px] md:mt-[40px] absolute">
                                Upload photo of PAN or Aadhaar card
                            </p>
                            {govtIdUrl && (
                                <img className="w-[5%] h-[7%] md:ml-[550px] absolute" src={govtIdUrl} alt="" />
                            )}
                            {errors.govtId && <p className="text-red-500 md:ml-[450px] ml-[22%] mt-[46px] md:mt-[2px] absolute">{errors.govtId}</p>}
                        </div>
                        <div className="md:flex md:px-[115px] md:gap-[50px] xl:px-[184px] mt-[90px] w-full">
                            <div className="text-black text-[14px] leading-[21px] font-normal ml-[24%] md:ml-[10px]">
                                Upload Company I-Card<span className="text-red-600"> *</span>
                            </div>
                            <div className="ml-[30%] md:ml-[300px] flex items-center mt-2 md:mt-0 absolute">
                                <label htmlFor="companyId-photo-upload" className="cursor-pointer mr-4">
                                    <FontAwesomeIcon icon={faArrowUpFromBracket} className="h-6 w-6 text-[#067C4E]" />
                                </label>
                                <span className="mr-4">OR</span>
                                <label htmlFor="companyId-camera-upload" className="cursor-pointer mr-4">
                                    <FontAwesomeIcon icon={faCamera} className="h-6 w-6 text-[#067C4E]" />
                                </label>
                                <input
                                    name="companyId"
                                    id="companyId-photo-upload"
                                    className="hidden"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleChange}
                                />
                                <input
                                    name="companyId"
                                    id="companyId-camera-upload"
                                    className="hidden"
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    onChange={handleChange}
                                />
                            </div>
                            {companyIdUrl && (
                                <img className="w-[5%] h-[7%] md:ml-[550px] absolute" src={companyIdUrl} alt="" />
                            )}
                            {errors.companyId && <p className="text-red-500 ml-[13%] md:ml-[450px] mt-[30px] md:mt-[2px] absolute">{errors.companyId}</p>}
                        </div> */}

                        <div className=" md:px-[115px] mt-[32px] xl:px-[184px]">

                            <div className="w-fit mx-auto hidden  items-center">
                                <button
                                    className="cursor-pointer bg-[#067C4E] text-white md:px-4 md:py-2 px-2 py-1  rounded-lg mr-4"
                                // onClick={handleDownload}
                                >
                                    <span><FontAwesomeIcon icon={faCircleDown} className="h-6 w-6 mr-4" /></span>
                                    Download Letter Head Format
                                </button>
                            </div>

                            <div className="flex mt-[32px] flex-row items-center">

                                <div className="text-black text-[14px] leading-[21px] font-normal ml-[10px]">
                                    Upload Letter Head
                                </div>

                                <div className="ml-[4%] md:ml-[10%]">
                                    <label htmlFor="photo-upload" className="cursor-pointer mr-4">
                                        <FontAwesomeIcon icon={faArrowUpFromBracket} className="h-6 w-6 text-[#067C4E]" />
                                    </label>
                                    <input
                                        id="photo-upload"
                                        className="hidden"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleChange}
                                    />
                                </div>

                            </div>

                            {/* {isFormSubmitted && errors.photo && (
                                <p className="text-red-500 m-[10px]">{errors.photo}</p>
                            )} */}
                        </div>

                        <div className="mt-[20px] md:mt-[32px] md:ml-[170px] bottom-0 md:w-[70%] px-4 py-2">
                            <p className="text-black text-[14px] leading-[21px] font-normal mb-2">User Declaration *:</p>
                            <p className="text-black text-[14px] leading-[21px] font-normal mb-4">
                                I affirm that I am a bonafide employee of {!formData.companyName ? "my organization" : `${formData.companyName}`} and that I am qualified to use this platform for the purpose of analyzing my organization's electricity/energy consumption and requirements.
                            </p>
                            <div className="flex items-center">
                                <input type="checkbox" id="user-declaration" className="mr-2" onClick={() => setAffirmation(!affirmation)} />
                                <label htmlFor="user-declaration" className="text-black text-[14px] leading-[21px] font-normal">
                                    I affirm the above statement
                                </label>
                            </div>
                        </div>

                        <div className="mt-[32px] flex justify-center">
                            <button
                                disabled={!affirmation}
                                onClick={handleSubmit}
                                className={`h-[44px] rounded-[100px] py-[10px] px-[25px] text-white text-[16px] leading-[24px] ${!affirmation ? 'bg-[#B5C6BF]' : 'bg-[#067C4E]'
                                    }`}
                            >
                                Sign Up
                            </button>
                        </div>
                    </div >
                </div>
            </div>
            {openPopup &&
                <SignUpPopup setOpenPopup={setOpenPopup} openPopUp={openPopup} userId={userId} handleOpenPopup={handleOpenPopup} />
            }
            <ToastContainer position="top-center" autoClose={3000} />
        </>
    );
};

export default SignUp;
