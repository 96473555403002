import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios'; // Import axios for making API calls
import { postData } from '../../api/apiDataExchange';

const UserInvitationPopup = ({ onClose }) => {
  const [emails, setEmails] = useState(['']);
  const [organizationName, setOrganizationName] = useState();
  const [orgId, setOrgId] = useState();
  const [errors, setErrors] = useState([]);

  const validateEmail = (emails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Split emails by comma and remove any leading/trailing whitespace
    const emailList = emails.split(',').map(email => email.trim());
    
    // Check each email against the regex
    return emailList.every(email => emailRegex.test(email));
  };

  const addEmailField = () => {
    setEmails([...emails, '']);
    setErrors([...errors, '']); // Add an empty error field for the new email
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);

    // Validate and set error for the specific email field
    const newErrors = [...errors];
    newErrors[index] = validateEmail(value) ? '' : 'Invalid email format';
    setErrors(newErrors);
  };

  const sendInvites = async (emails) => {
    try {
      // Replace with your actual API endpoint
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/invite-user`, { emails,orgId });
      if (response.status === 200) {
        toast.success('Invites sent successfully!');
        onClose(); 
      }
    } catch (error) {
		console.log({error});
		if(error['response']['data']['message']){
			toast.error(error['response']['data']['message']);
		}else{
			toast.error('Failed to send invites. Please try again.');
		}
		
    
      console.error('Error sending invites:', error);
    }
  };

  const handleSubmit = async () => {
    const splitEmails = emails
    .flatMap(email => email.split(','))
    .map(email => email.trim())
    .filter(email => email.length > 0); // Remove any empty email strings

  const invalidEmails = splitEmails.filter(email => !validateEmail(email));
  if (invalidEmails.length > 0) {
    toast.error('Invalid Email Address');
    return;
  }

  await sendInvites(splitEmails); 
  };

  const isFormValid = emails.every((email) => validateEmail(email));

  useEffect(() => {
    const getdata = async () => {
      const payload = {
        buyerId: localStorage.getItem("userId"),
      };
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        payload
      );

      if (response?.data?.data?.results?.length > 0) {
       console.log({orgData:response?.data?.data?.results});
	   setOrganizationName(response?.data?.data?.results[0].name);
	   setOrgId(response?.data?.data?.results[0]._id)
      }
    };
    getdata();
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white mx-4 md:mx-0 p-6 rounded-2xl shadow-lg w-[400px] relative">
      <div className='flex justify-between'>     
          

        <h2 className="text-2xl  font-bold mb-2">Invite User</h2>
        <button
          onClick={onClose}
          className=" bg-gray-400 rounded-md p-[2px]  h-[30px] text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        </div>

		<hr  />

        <div className="py-4 mt-1">
          <strong className='text-[14px] font-medium'>Organization Name: </strong>
          <span className="text-sm text-gray-600">{organizationName}</span>
        </div>
        {/* <div className="mb-4">
          <strong>Address: </strong>
          <span className="text-sm text-gray-600">{organizationAddress}</span>
        </div> */}

        {emails.map((email, index) => (
          <div key={index} className="mb-2 mt-2">
            <label className="block text-sm font-medium mb-1">Email :</label>
            <input
              type="email"
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              placeholder="Enter email"
              className="border focus:border-gray-400 text-base p-2 w-full rounded-md"
            />
            {errors[index] && (
              <p className="text-red-500 text-sm mt-1">{errors[index]}</p>
            )}
            <p className="text-red-500 text-xs mt-4">Note: For multiple email addresses, please separate them with a comma.</p>
          </div>
        ))}
	<hr  />
        <div className="flex justify-end mt-4">
          <button
            onClick={handleSubmit}
            disabled={!isFormValid}
            className={`px-2 py-1 text-base rounded-md ${isFormValid ? 'bg-green-700 text-white' : 'bg-green-700 text-white cursor-not-allowed'}`}
          >
            Send Invitation
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInvitationPopup;
