import axios from "axios";

export class UnitService {

  async getAllUnitsWithBuyerId(data) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/unit/list`,
        data
      );
      console.log({ response });
      return  response.data.data;
    } catch (err) {
      console.log(err);
    }
  }

  async cloneUnitSettingsData(selectedUnit, selectedUnits) {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user?.result?.token;

      let responseData = [];
      selectedUnits.forEach(async (unit) => {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/unit/${unit}`,

          {
            buyerId: this.buyerId,
            excelTimeSlot: selectedUnit.settings?.excelTimeSlot,
            holidayList: selectedUnit.settings?.holidayList,
            numberOfWeekdayShifts: selectedUnit.settings?.numberOfWeekdayShifts,
            weekdayShiftSlots: selectedUnit.settings?.weekdayShiftSlots,
            numberOfShiftsOnSunday: selectedUnit.settings?.numberOfShiftsOnSunday,
            sundayShiftSlots: selectedUnit.settings?.sundayShiftSlots,
            numberOfShiftsOnSaturday: selectedUnit.settings?.numberOfShiftsOnSaturday,
            saturdayShiftSlots: selectedUnit.settings?.saturdayShiftSlots,
            firstDayOfMonth: selectedUnit.settings?.firstDayOfMonth,
            nonProductiveDayDuration: selectedUnit.settings?.nonProductiveDayDuration,
            isBRSRMandatory: selectedUnit.settings?.isBRSRMandatory,
            isCBAMApplicable: selectedUnit.settings?.isCBAMApplicable,
            esTarget: selectedUnit.settings?.esTarget,
            esTargetReference: selectedUnit.settings?.esTargetReference,
            exporter: selectedUnit.settings?.exporter,
            maintenanceDateAndDuration: selectedUnit.settings?.maintenanceDateAndDuration,
            planForLoadEnhancement: selectedUnit.settings?.planForLoadEnhancement,
            loadModificationExisting: selectedUnit.settings?.loadModificationExisting,
            loadAdditionNewPremises: selectedUnit.settings?.loadAdditionNewPremises,
            loadType: selectedUnit.settings?.loadType,
            capacityOfLoad: selectedUnit.settings?.capacityOfLoad,
            operatingHours: selectedUnit.settings?.operatingHours,
          }
        );
        console.log({ response });
        responseData.push({
          id: response.data.result._id,
          status: response.data.error.code,
        });
      });


      const allStatus200 = responseData.every((data) => data.status === 200);
      return allStatus200;

    } catch (err) {
      console.log(err);
    }
  }
}
